import { useEffect } from "react";
import useLDClient from "launchdarkly-react-client-sdk/lib/useLDClient";
import { useAuth } from "../auth/useAuth";

const LDUserIdentifier = () => {
  const { user } = useAuth();
  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient || !user) return;

    const ldUser: any = {
      kind: "user",
      key: "anonymous",
    };

    if (user) {
      const { email, preferred_username } = user;
      ldUser.key = email;
      ldUser.name = preferred_username;
      ldUser.email = email;
    }

    ldClient.identify(ldUser);
  }, [user, ldClient]);

  return null;
};

export default LDUserIdentifier;
