import React from "react";
import { HostContext } from "./host.context";

export const HostProvider = ({ host, children }) => {
  return (
    <HostContext.Provider value={{ host: host ?? "" }}>
      {children}
    </HostContext.Provider>
  );
};
