import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import loadable from "@loadable/component";
import environment from "environment";
import { initAnalytics } from "libs/amplitudeHelper";
import useHotjar from "libs/useHotjar";

import { useAuth, useRefreshToken } from "components/app/auth/useAuth";
import Loading from "components/app/Loading";
import "react-bnb-gallery/dist/style.css";
import { useAgenciaContext } from "context/agencia/agencia.context";
import useFlags from "launchdarkly-react-client-sdk/lib/useFlags";

const SignIn = loadable(() => import("components/app/auth/SignIn"));
const SignOut = loadable(() => import("components/app/auth/SignOut"));
const Callback = loadable(() => import("components/app/auth/Callback"));
const Home = loadable(() => import("components/app/Home"));
const Publicacion = loadable(
  () => import("components/app/publicacion/nueva/Publicacion")
);
const Cotizador = loadable(() => import("components/app/cotizador/Cotizador"));
const EscanearQR = loadable(() => import("./escanearQRStepper/EscanearQR"));
const Perfil = loadable(() => import("./perfil/Perfil"));
const HomeInterno = loadable(() => import("./HomeInterno"));
const TerminosYCondiciones = loadable(
  () => import("./TyC/TerminosYCondiciones")
);

interface IAppProps {
  history: any;
}

const defaultHistory = createBrowserHistory();

const App = ({ history }: IAppProps) => {
  const { isAuthenticated, isLoading: loadingUser, user } = useAuth();
  useRefreshToken();
  useHotjar();

  useEffect(() => {
    initAnalytics("v1.0", environment.amplitudeKey);
  }, []);

  return (
    <Router history={history || defaultHistory}>
      {loadingUser && <Loading />}
      {!loadingUser && !isAuthenticated && <PublicRoutes />}
      {!loadingUser && isAuthenticated && user.userType === "Agenciero" && (
        <AgencieroRoutes />
      )}
      {!loadingUser && isAuthenticated && user.userType === "Interno" && (
        <InternoRoutes />
      )}
      {!loadingUser && isAuthenticated && user.userType === "Productor" && (
        <ProductorRoutes />
      )}
    </Router>
  );
};

const PublicRoutes = () => (
  <Switch>
    <Route exact path="/signin">
      <SignIn />
    </Route>
    <Route exact path="/callback">
      <Callback />
    </Route>
    <Redirect to="/signin" />
  </Switch>
);

const InternoRoutes = () => (
  <Switch>
    <Route exact path="/autos">
      <HomeInterno />
    </Route>
    <Route exact path="/autos/cotizador">
      <Cotizador />
    </Route>
    <Route exact path="/autos/escanearqr">
      <EscanearQR />
    </Route>
    <Route exact path="/signout">
      <SignOut />
    </Route>
    <Redirect to="/autos" />
  </Switch>
);

const ProductorRoutes = () => {
  const { cotizarVehiculos } = useFlags();

  return (
    <Switch>
      {cotizarVehiculos ? (
        <Route exact path="/autos/cotizador">
          <Cotizador />
        </Route>
      ) : null}
      <Redirect key={`redirect-productor`} to="/" />,
    </Switch>
  );
};

const AgencieroRoutes = () => {
  const { agencia, loading } = useAgenciaContext();
  const { cotizarVehiculos } = useFlags();
  return (
    <Switch>
      {!loading && !agencia && AgenciaNoCreadaRoutes(cotizarVehiculos)}
      {!loading && agencia && AgenciaCreadaRoutes(cotizarVehiculos)}
    </Switch>
  );
};

const AgenciaCreadaRoutes = (cotizarVehiculos: boolean) => {
  return [
    <Route key={`route-autos`} exact path="/autos">
      <Home />
    </Route>,
    <Route key={`route-publicacion`} exact path="/autos/publicacion">
      <Publicacion />
    </Route>,
    cotizarVehiculos ? (
      <Route key={`route-cotizador`} exact path="/autos/cotizador">
        <Cotizador />
      </Route>
    ) : null,
    <Route key={`route-publicacion-id`} exact path="/autos/publicacion/:id">
      <Publicacion />
    </Route>,
    <Route key={`route-qr`} exact path="/autos/escanearqr">
      <EscanearQR />
    </Route>,
    <Route key={`route-perfil`} exact path="/autos/perfil">
      <Perfil />
    </Route>,
    <Route key={`route-signout`} exact path="/signout">
      <SignOut />
    </Route>,
    <Redirect key={`redirect-autos`} to="/autos" />,
  ];
};
const AgenciaNoCreadaRoutes = (cotizarVehiculos: boolean) => {
  const history = useHistory();

  useEffect(() => {
    const vioTerminosYCondiciones = localStorage.getItem(
      "vioTerminosYCondicionesPrendo"
    )
      ? true
      : false;
    if (!vioTerminosYCondiciones) history.push("/autos/terminosycondiciones");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    <Route key={`route-autos`} exact path="/autos">
      <Home />
    </Route>,
    cotizarVehiculos ? (
      <Route key={`route-cotizador`} exact path="/autos/cotizador">
        <Cotizador />
      </Route>
    ) : null,
    <Route key={`route-tyc`} exact path="/autos/terminosycondiciones">
      <TerminosYCondiciones />
    </Route>,
    <Route key={`route-signout`} exact path="/signout">
      <SignOut />
    </Route>,
    <Redirect key={`redirect-tyc`} to={"/autos/terminosycondiciones"} />,
  ];
};

export default App;
