import { useLogsContext } from "context/logs/logs.context";

import pino, { Logger, stdTimeFunctions } from "pino";
import { useCallback } from "react";
import store from "store2";
interface IPinoLogger
  extends Logger<{
    browser: {
      write: (logObject: object) => void;
    };
    timestamp: () => string;
  }> {
  setCallback?: (callbackFn: () => void) => void;
}
interface IUserData {
  euroUserId: string;
  sub: string;
  perms: string[];
  email: string;
  userName: string;
  userType: string;
}
interface IContextData {
  userAgent: string;
  path: string;
}

interface ILogObject {
  time: number;
  level: number;
  msg: string;
}
interface ILogEvent extends ILogObject {
  application: string;
  domain: string;
  environment: string;
  context: IContextData;
  user: IUserData;
}

const isLocalhost = process.env.REACT_APP_ENV === "localhost";
const isStaging = process.env.REACT_APP_ENV === "staging";
const isProduction = process.env.REACT_APP_ENV === "production";

const logToBrowserConsole = (logEvent: ILogEvent) => {
  if (isLocalhost || isStaging) {
    logEvent.level <= 30 && console.log(logEvent);
    logEvent.level === 40 && console.warn(logEvent);
    logEvent.level >= 50 && console.error(logEvent);
  }

  isProduction && logEvent.level >= 50 && console.error(logEvent.msg);
};

const useLogs = () => {
  const { enqueueLogEvent, setCallback } = useLogsContext();
  const user = store.get("user");
  const accessToken = store.get("accessToken");
  const isOnNativeApp = store.get("isOnNativeApp");
  const deviceInfo = isOnNativeApp ? store.get("deviceInfo") : "NotNativeApp";

  const protocol = isLocalhost ? "http" : "https";

  const location = window.location.pathname;

  const createLogEventObject = useCallback(
    (logObject: ILogObject) => {
      return {
        ...logObject,
        application: "MKP Autos",
        domain: `${protocol}://${process.env.REACT_APP_DOMAIN}`,
        environment: process.env.REACT_APP_ENV,
        context: {
          userAgent: navigator.userAgent,
          path: location,
        },
        user: {
          euroUserId: user?.euroUserId,
          sub: user?.sub,
          perms: user?.perms,
          email: user?.email,
          userName: user?.preferred_username,
          userType: user?.userType,
          isOnNativeApp: isOnNativeApp,
          deviceInfo: deviceInfo,
          ...(accessToken && { accessToken }),
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const logger: IPinoLogger = pino({
    browser: {
      write: (logObject: ILogObject) => {
        const logEvent: ILogEvent = createLogEventObject(logObject);
        logToBrowserConsole(logEvent);
        enqueueLogEvent(logEvent);
      },
      timestamp: stdTimeFunctions.isoTime,
    },
  });

  logger.setCallback = (callbackFn) => {
    setCallback(() => callbackFn);
  };

  return { logger };
};

export default useLogs;
