import { createContext, useContext } from "react";

interface LogsContextType {
  enqueueLogEvent: (logData: object) => void;
  setCallback: React.Dispatch<React.SetStateAction<() => void>>;
}

export const LogsContext = createContext<LogsContextType>(null);

export const useLogsContext = () => {
  return useContext(LogsContext);
};
