import {
  CloudWatchLogsClient,
  CreateLogStreamCommand,
  DescribeLogStreamsCommand,
  InputLogEvent,
  PutLogEventsCommand,
  PutLogEventsCommandInput,
} from "@aws-sdk/client-cloudwatch-logs";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { LogsContext } from "context/logs/logs.context";
import store from "store2";

const LOG_STREAM_STORE_NAME = "logStreamData";
const LOG_STREAM_TTL = 4 * 60 * 60 * 1000; // Four hours
const isTestingEnvironment = process.env.REACT_APP_ENV === "test";

enum CloudWatchExceptions {
  invalidSequenceToken = "InvalidSequenceTokenException",
}

const client = new CloudWatchLogsClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_LOGGER_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_LOGGER_SECRET,
  },
});

const logQueue = new Array<InputLogEvent>();

const enqueueLogEvent = (logData: object) => {
  // Do nothing in testing environment.
  if (isTestingEnvironment) return;

  logQueue.push({
    message: JSON.stringify(logData),
    timestamp: Date.now(),
  });
};

const validateLogStreamData = (logStreamData: any): boolean => {
  if (!logStreamData) return false;

  const { name, expiresAt } = logStreamData;

  if (!name) return false;
  if (!expiresAt) return false;
  if (expiresAt <= Date.now()) return false;

  return true;
};

export const LogsProvider = ({ children }) => {
  const [logStreamName, setLogStreamName] = useState<string>();
  const [sequenceToken, setSequenceToken] = useState<string | null>();
  const [callback, setCallback] = useState<() => void>(null);

  const createLogStream = useCallback(() => {
    const logStream = uuidv4();
    const createLogStreamCommand = new CreateLogStreamCommand({
      logGroupName: process.env.REACT_APP_LOG_GROUP,
      logStreamName: logStream,
    });

    client
      .send(createLogStreamCommand)
      .then(() => {
        setLogStreamName(logStream);
        setSequenceToken(null);
        store.set(LOG_STREAM_STORE_NAME, {
          name: logStream,
          expiresAt: Date.now() + LOG_STREAM_TTL,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  // Create log stream.
  useEffect(() => {
    // Disable requests to AWS in testing environment.
    if (isTestingEnvironment) return;

    // Check if there's a previously created log stream.
    const logStreamData = store.get(LOG_STREAM_STORE_NAME);
    const isLogStreamDataValid = validateLogStreamData(logStreamData);

    if (!isLogStreamDataValid) createLogStream();
    else {
      const describeLogStreamCommand = new DescribeLogStreamsCommand({
        logGroupName: process.env.REACT_APP_LOG_GROUP,
        logStreamNamePrefix: logStreamData.name,
      });

      client
        .send(describeLogStreamCommand)
        .then((data) => {
          const logStream = data.logStreams[0];
          if (logStream) {
            setLogStreamName(logStream.logStreamName);
            // Setting the sequence token to null in order to indicate that the log stream is empty.
            setSequenceToken(
              logStream.uploadSequenceToken === undefined
                ? null
                : logStream.uploadSequenceToken
            );
          } else createLogStream();
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Disable requests to AWS in testing environment.
    if (isTestingEnvironment) return;

    const isLoggerReady =
      client &&
      logStreamName &&
      sequenceToken !== undefined &&
      logQueue.length > 0;

    if (!isLoggerReady) return;

    const logParams: PutLogEventsCommandInput = {
      logEvents: [...logQueue],
      logGroupName: process.env.REACT_APP_LOG_GROUP,
      logStreamName: logStreamName,
      // If log stream is empty, there's no need to provide the sequence token
      ...(sequenceToken && { sequenceToken }),
    };

    const command = new PutLogEventsCommand(logParams);

    client
      .send(command)
      .then((data) => {
        setSequenceToken(data?.nextSequenceToken);
        // Empty log queue
        logQueue.splice(0, logQueue.length);
      })
      .catch((error) => {
        error.name === CloudWatchExceptions.invalidSequenceToken &&
          setSequenceToken(error.expectedSequenceToken);
        console.log({ error });
      })
      .finally(() => {
        if (callback) {
          callback();
          setCallback(null);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logQueue.length, logStreamName, sequenceToken]);

  return (
    <LogsContext.Provider value={{ enqueueLogEvent, setCallback }}>
      {children}
    </LogsContext.Provider>
  );
};
