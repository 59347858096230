const environment = {
  version: process.env.REACT_APP_VERSION,
  versionDescription:
    `v${process.env.REACT_APP_VERSION}` +
    (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== "production"
      ? ` (${process.env.REACT_APP_ENV.substring(0, 3)})`
      : ""),
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  },
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  branchKey: process.env.REACT_APP_BRACH_API_KEY,
  mapsKey: process.env.REACT_APP_MAPS_API_KEY,
  marketplaceUrl: process.env.REACT_APP_API_MARKETPLACE_URL,
  compradorUrl: process.env.REACT_APP_MARKETPLACE_COMPRADOR_URL,
  marketplaceUploadUrl: process.env.REACT_APP_API_MARKETPLACE_UPLOAD_URL,
  marketplaceImagesUrl: process.env.REACT_APP_MARKETPLACE_IMAGES_URL,
  originacionUrl: process.env.REACT_APP_API_ORIGINACION_URL,
  vehiclesUrl: process.env.REACT_APP_API_VEHICLES_URL,
  catalogosUrl: process.env.REACT_APP_API_CATALOGOS_URL,
  name: process.env.REACT_APP_ENV,
};

export default environment;
