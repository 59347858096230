import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  GeocodeResult,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

export class Suggestion {
  id: string;
  description: string;
}

export function usePlaces(): any {
  const { ready, value, suggestions, setValue, clearSuggestions, init } =
    usePlacesAutocomplete({
      initOnMount: true,
      requestOptions: {
        region: "AR",
      },
      debounce: 300,
      cache: 24 * 60 * 60,
    });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const getState = (result: GeocodeResult): Promise<string> =>
    new Promise<string>((resolve, reject) => {
      try {
        let state = null;

        result.address_components.forEach(({ short_name, types }) => {
          if (types.includes("administrative_area_level_1"))
            state = short_name
              .replace("Provincia del ", "")
              .replace("Provincia de ", "");
        });

        resolve(state);
      } catch (error) {
        reject(error);
      }
    });

  const getCity = (result: GeocodeResult): Promise<string> =>
    new Promise<string>((resolve, reject) => {
      try {
        let city = null;

        for (let address of result.address_components) {
          if (address.types.includes("locality")) {
            city = address.short_name;
            break;
          }
          if (address.types.includes("administrative_area_level_2")) {
            city = address.short_name;
          }
        }

        resolve(city);
      } catch (error) {
        reject(error);
      }
    });

  return {
    placeSearch: value,
    setPlaceSearch: setValue,
    suggestions: suggestions.data
      ? suggestions.data.map((s) => {
          const suggestion = new Suggestion();
          suggestion.id = s.place_id;
          suggestion.description = s.description
            .replace("Provincia de ", "")
            .replace(", Argentina", "");
          return suggestion;
        })
      : [],
    status: suggestions.status,
    ready,
    clear: clearSuggestions,
    getGeocode,
    getLatLng,
    getState,
    getCity,
    getZipCode,
    onClickOutsideRef: ref,
    init,
  };
}
