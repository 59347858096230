import { useEffect, useState } from "react";

const HOTJAR_CONFIG = [
  { url: "https://agencias.v3.mg-group.net.ar/", clientId: "1586788" },
  { url: "https://web.agencias.mg-group.net.ar/", clientId: "1642652" },
  { url: "https://app.mg-group.com.ar/", clientId: "1767938" },
  { url: "https://app.v1.mg-group.com.ar/", clientId: "1776775" },
];

const useHotjar = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      const config = HOTJAR_CONFIG.find((x) =>
        x.url.includes(window.location.origin)
      );

      if (config) hotjarLoader(config.clientId)();

      setIsLoaded(true);
    }
  }, [isLoaded, setIsLoaded]);
};

export default useHotjar;

const hotjarLoader = (clientId) => (
  h = window,
  o = document,
  t = "https://static.hotjar.com/c/hotjar-",
  j = ".js?sv=",
  a,
  r
) => {
  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: clientId, hjsv: 6 };
  a = o.getElementsByTagName("head")[0];
  r = o.createElement("script");
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};
