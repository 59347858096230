import React from "react";
import Lottie from "lottie-web-react";
import loading from "assets/animations/loading.json";
import { styled } from "@mui/material/styles";

const Loading = () => {
  return (
    <Container>
      <LottieContainer>
        <Lottie
          options={{
            loop: true,
            animationData: loading,
          }}
          playingState={"play"}
        />
      </LottieContainer>
    </Container>
  );
};

export default Loading;

const Container = styled("div")({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "white",
});

const LottieContainer = styled("div")({ width: 60, alignSelf: "center" });
