import Fuse from "fuse.js";

interface IItemProps {
  value: string | number;
  label: string | number;
}
const MAX_RESULTSET_LENGTH = 100;

export const filterItems = (items: IItemProps[], filter: string = "") => {
  const fuzeConfig = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: filter.length,
    keys: ["label"],
  };

  const fuse = new Fuse(items, fuzeConfig);
  const filterList = fuse.search(filter).slice(0, MAX_RESULTSET_LENGTH);

  return filterList;
};
