export const isOnNativeApp = localStorage.getItem("isOnNativeApp")
  ? JSON.parse(localStorage.getItem("isOnNativeApp"))
  : false;

export const nativeCredentials = localStorage.getItem("nativeCredentials")
  ? JSON.parse(localStorage.getItem("nativeCredentials"))
  : "";

export const onNativeMessageReceived = (eventData) => {
  return eventData;
};

const sendMessageToNative = (message) => {
  if (window.ReactNativeWebView)
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

export const openNativeLink = (url) => {
  sendMessageToNative({ type: "WEB_OPEN_URL", payload: { url } });
};

export const openNativeShare = (url) => {
  sendMessageToNative({ type: "WEB_SHARE_URL", payload: { url } });
};

export const showNativePopup = (name) => ({
  type: "SHOW_NATIVE_POPUP",
  payload: {
    menuItem: name,
  },
});
