import { createTheme, alpha } from "@mui/material/styles";
import { pxToRem } from "@mg.marketplace/front-common/helpers";
import { grey as Grey } from "@mui/material/colors";

const purple1 = "hsl(263, 55%, 53%)";
const purple2 = "hsl(260, 44%, 45%)";
const purple3 = "hsl(263, 45%, 35%)";
const purple4 = "hsl(231, 44%, 94%)";
const purple800 = "hsl(263, 46%, 45%)";

const cyan1 = "hsl(190, 63%, 57%)";
const cyan2 = "hsl(190, 52%, 65%)";
const cyan3 = "hsl(190, 63%, 85%)";
const cyan200 = "hsl(190, 100%, 92%)";
const cyan700 = "hsl(190, 57%, 51%)";

const prendo2 = "hsl(259, 96%, 56%)";
const prendo3 = "hsl(183, 100%, 46%)";
const prendo4 = "hsl(236, 21%, 70%)";

const fontFamily = ["Nunito", "Helvetica", "Arial", "sans-serif"].join(",");

const mediaBreakpoints = {
  smallMobile: "@media (max-width: 321px)",
  mobile: "@media (max-width: 1023px)",
  desktop: "@media (min-width: 1024px)",
};

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    gray: PaletteColor;
    bluegrey: PaletteColor;
    prendo?: PaletteColor;
  }
  interface PaletteOptions {
    gray?: PaletteColorOptions;
    bluegrey?: PaletteColorOptions;
    prendo?: PaletteColorOptions;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      light: purple1,
      main: purple2,
      dark: purple3,
      contrastText: purple4,
      "100": "#EDE7F8",
      "800": purple800,
    },
    secondary: {
      light: cyan3,
      main: cyan2,
      dark: cyan1,
      "200": cyan200,
      "700": cyan700,
    },
    prendo: {
      light: prendo3,
      main: prendo2,
      dark: purple3,
      contrastText: prendo4,
    },
    grey: {
      400: Grey[400],
      600: Grey[600],
    },
    bluegrey: {
      400: "#9294aa",
    },
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontSize: pxToRem(44),
      lineHeight: 1.3,
      fontWeight: 800,
      marginBottom: pxToRem(12.8),
    },
    h3: {
      fontSize: pxToRem(28),
      fontWeight: 600,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true, fullWidth: true },
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
          fontWeight: "bold",
          lineHeight: "1.38",
          letterSpacing: pxToRem(0.48),
          padding: pxToRem(13),
          minHeight: 52,
          textTransform: "none",
        },
        text: {
          "&:disabled": {
            backgroundColor: "#eeeeee",
            color: "#9e9e9e",
          },
        },
        containedPrimary: {
          backgroundColor: purple2, //fade(purple2, 0.2),
          color: "#ffffff",
          "&:hover": {
            backgroundColor: purple1,
          },
        },
        containedSecondary: {
          backgroundColor: `${cyan1}`,
          color: "#ffffff",
          "&:hover": {
            backgroundColor: alpha(cyan1, 0.8),
          },
        },
        outlined: {
          "&:disabled": {
            borderWidth: "0.125rem",
            color: "#bdbdbd",
            borderColor: "#bdbdbd",
          },
        },
        outlinedPrimary: {
          borderColor: purple2,
          borderWidth: "0.150rem",
          "&:hover": {
            borderColor: purple1,
            borderWidth: "0.150rem",
          },
        },
        outlinedSecondary: {
          borderWidth: "0.150rem",
          borderColor: cyan2,
          color: cyan2,
          "&:hover": {
            color: cyan1,
            borderColor: cyan1,
            borderWidth: "0.150rem",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: { type: "button" },
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
      },
    },
    MuiTextField: {
      defaultProps: { fullWidth: true },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          transition: "all 0.5s ease",
          width: "initial",
          minWidth: "initial",
          paddingLeft: 0,
          paddingRight: 0,
          borderBottom: `${pxToRem(4)} solid transparent`,
          "&$selected": {
            borderBottom: `${pxToRem(4)} solid ${purple1}`,
          },
        },
        label: {
          fontSize: `${pxToRem(10.88)} !important`,
          fontWeight: 800,
          lineHeight: 1.36,
          marginTop: 3,
          letterSpacing: pxToRem(0.22),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&$focused": {
            "& fieldset": {
              borderColor: `${purple1} !important`,
            },
          },
          "&:hover fieldset": {
            borderColor: `${Grey[500]} !important`,
          },
          "& fieldset": {
            borderColor: Grey[500],
          },
        },
        input: {
          caretColor: purple1,
          padding: pxToRem(17),
          fontFamily: fontFamily,
          fontSize: pxToRem(16),
          fontWeight: 500,
          lineHeight: 1.25,
          letterSpacing: pxToRem(0.15),
          color: Grey[800],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: "translate(14px, 17px) scale(1)",
          color: Grey[500],
          fontSize: pxToRem(16),
          fontWeight: 500,
          lineHeight: 1.25,
          letterSpacing: pxToRem(0.15),
          "&$focused": { color: purple1 },
        },
        root: {
          "&$focused": { color: purple1 },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          fontFamily: fontFamily,
          fontSize: "1.25rem",
          fontWeight: 600,
          lineHeight: 1.35,
          letterSpacing: pxToRem(0.19),
          color: Grey[800],
          caretColor: purple1,
          "&::placeholder": {
            color: Grey[400],
            opacity: 1,
          },
        },
        underline: {
          "&:before": {
            borderBottom: `${pxToRem(1)} solid ${Grey[500]}`,
          },
          "&:after": {
            borderBottom: `${pxToRem(2)} solid ${purple1}`,
            transform: `scaleX(0)`,
          },
          "&$focused:after": {
            borderBottom: `${pxToRem(2)} solid ${purple1}`,
            transition: `all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms`,
            transform: `scaleX(1)`,
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: `${pxToRem(1)} solid ${purple1}`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: pxToRem(13),
          paddingRight: 10,
          paddingLeft: 10,
          fontWeight: "bold",
          lineHeight: 1.33,
          color: purple1,
        },
        body: {
          fontSize: pxToRem(12),
          paddingRight: 10,
          paddingLeft: 10,
          lineHeight: 1.33,
          userSelect: "text",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: { cursor: "pointer" },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        staticTooltipLabel: {
          width: "max-content",
        },
      },
    },
  },
});

export default theme;
export { mediaBreakpoints };
