import { OperationVariables, ApolloQueryResult } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";
import { createContext, useContext } from "react";
import { Agencia } from "types";
export interface AgenciaContextType {
  agencia: Agencia;
  loading: boolean;
  error: ApolloError;
  refetch: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<AgenciaData>>;
}

export interface AgenciaData {
  agencia: Agencia;
}

export const AgenciaContext = createContext<AgenciaContextType>(null);

export const useAgenciaContext = () => {
  return useContext(AgenciaContext);
};
