let _amplitude;
const getAmplitude = async () => {
  if (!_amplitude) _amplitude = await import("amplitude-js");
  return _amplitude.getInstance();
};

let _fingerprint;
const getFingerprint = async () => {
  if (!_fingerprint) {
    const factory = await import("@fingerprintjs/fingerprintjs");

    const fp = await factory.load();
    const result = await fp.get();
    _fingerprint = result.visitorId;
  }

  return _fingerprint;
};

const isLocalhost =
  process.env.REACT_APP_ENV === "localhost" || process.env.name === "localhost";
const isTestingEnvironment =
  process.env.REACT_APP_ENV === "test" || process.env.name === "test";

const disableAnalytics = isLocalhost || isTestingEnvironment;

export const initAnalytics = async (appVersion: string, apiKey) => {
  if (disableAnalytics) return; // disable analytics on localhost

  const amplitude = await getAmplitude();
  amplitude.init(apiKey ? apiKey : "");
  amplitude.setVersionName(appVersion);
};

export const logOutUserAnalytics = async () => {
  if (disableAnalytics) return; // disable analytics on localhost

  const amplitude = await getAmplitude();
  amplitude.setUserId(null);
};

export const logInUserAnalytics = async (
  email: string,
  name: string,
  userId: string,
  euroUserId: string,
  appVersion: string
) => {
  if (disableAnalytics) return; // disable analytics on localhost

  const amplitude = await getAmplitude();

  amplitude.setUserId(userId);
  amplitude.setUserProperties({
    email,
    nombre: name,
    userId,
    euroUserId,
    appVersion,
  });
};

export const logEventAnalytics = async (eventType: string, eventData: any) => {
  if (disableAnalytics) {
    console.log("######################################################");
    console.log("analytics.eventType: " + eventType);
    console.log("analytics.eventData:");
    console.log(JSON.stringify(eventData));

    return;
  }

  const fingerprint = await getFingerprint();

  const amplitude = await getAmplitude();
  amplitude.logEvent(eventType, { fingerprint, eventData });
};
