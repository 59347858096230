/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo } from "react";
import { useAuthContext } from "context/auth/auth.context";
import jwt_decode from "jwt-decode";
import getMGUser from "libs/getMGUser";
import { isOnNativeApp, nativeCredentials } from "libs/nativeAppMessaging";
import useLogs from "libs/useLogs";

const useIsAuthenticated = (expiresAt: number) => {
  return useMemo(() => {
    return new Date().getTime() < expiresAt;
  }, [expiresAt]);
};

export const useRefreshToken = () => {
  const { isAuthenticated, expiresAt, renewSession, setSession } = useAuth();

  useEffect(() => {
    let timerForWebRefresh = null;

    const TWO_MINUTES = 2 * 60 * 1000;
    let timeToExpire = 0;
    if (expiresAt > 0) {
      timeToExpire = expiresAt - new Date().getTime();
      timeToExpire =
        timeToExpire > TWO_MINUTES ? timeToExpire - TWO_MINUTES : 0;
    }

    if (
      isOnNativeApp &&
      nativeCredentials &&
      nativeCredentials.accessToken !== localStorage.getItem("accessToken")
    ) {
      setSession({
        expiresIn: nativeCredentials.expiresIn,
        accessToken: nativeCredentials.accessToken,
        idToken: nativeCredentials.idToken,
      });
    } else {
      timerForWebRefresh = setTimeout(() => {
        if (isAuthenticated) {
          renewSession();
        }
      }, timeToExpire);
    }

    return () => clearTimeout(timerForWebRefresh);
  }, [isAuthenticated, expiresAt, renewSession]);
};

export const useAuth = () => {
  const { authState, authDispatch } = useAuthContext();
  const { auth0, expiresAt, user, isLoading } = authState;
  const { logger } = useLogs();

  const isAuthenticated = useIsAuthenticated(
    expiresAt ? expiresAt : new Date().getTime()
  );

  const canales =
    user && user.perms && user.perms.length > 0
      ? user.perms.filter((p) => p !== "MG-Group" && p !== "Tester")
      : [];
  const canal = canales.length === 1 ? parseInt(canales[0]) || 0 : 0;

  const loginSocial = useCallback(
    (provider) => {
      try {
        authDispatch({ type: "LOGIN_INITED" });
        auth0.authorize({
          connection: provider,
          prompt: "login",
        });
      } catch (error) {
        logger.setCallback(() => {
          authDispatch({ type: "LOGIN_FAILED" });
          logout();
        });
        logger.error({ error, provider }, "Error en social login.");
      }
    },
    [auth0]
  );

  const logout = useCallback(
    async (returnUrl = "") => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("expiresAt");
      await auth0.logout({
        returnTo: window.location.origin + returnUrl,
      });
      authDispatch({ type: "LOG_OUT" });
    },
    [auth0]
  );

  const setSession = useCallback((authResult) => {
    localStorage.setItem("accessToken", authResult.accessToken);

    const expiresAt = (authResult.expiresIn ?? 0) * 1000 + new Date().getTime();
    localStorage.setItem("expiresAt", expiresAt.toString());

    const auth0TokenData = jwt_decode(authResult.accessToken);
    const user = getMGUser(auth0TokenData);

    localStorage.setItem("user", JSON.stringify(user));
    authDispatch({
      type: "LOGIN_COMPLETED",
      payload: {
        expiresAt: expiresAt,
        user: user,
      },
    });
  }, []);

  const renewSession = useCallback(() => {
    auth0.checkSession({}, (err: any, authResult: any) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult);
      } else if (err) {
        logger.setCallback(logout);
        logger.error(
          { error: err },
          `Error on renewSession. Could not get a new token (${err.error}: ${err.error_description}).`
        );
      }
    });
  }, []);

  const handleAuthentication = useCallback(() => {
    auth0.parseHash(async (err: any, authResult: any) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        setSession(authResult);
      } else if (err) {
        logger.setCallback(logout);
        logger.error(
          { error: err },
          `Error on handleAuthentication: ${err.error}.`
        );
      }
    });
  }, []);

  return {
    isLoading,
    loginSocial,
    logout,
    handleAuthentication,
    renewSession,
    isAuthenticated,
    expiresAt,
    user,
    canal,
    setSession,
  };
};
