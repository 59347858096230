import { createContext, useContext } from "react";
import { AuthState } from "./auth.provider";

export interface AuthContextType {
  authState: AuthState,
  authDispatch: any,
}

export const AuthContext = createContext<AuthContextType>(null);

export const useAuthContext = () => {
  return useContext(AuthContext);
};
