import React, { useReducer } from "react";
import { AuthContext } from "./auth.context";
import { WebAuth } from "auth0-js";
import { IMGUser } from "libs/getMGUser";

export interface AuthState {
  auth0?: WebAuth,
  expiresAt: number,
  user: IMGUser,
  isLoading: boolean,
  error?: any
}


const isBrowser = typeof window !== "undefined";
const initialState: AuthState = {
  expiresAt:
    isBrowser && !!localStorage.getItem("expiresAt")
      ? parseInt(JSON.parse(localStorage.getItem("expiresAt") ?? "{}"))
      : null,
  user:
    isBrowser && !!localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") ?? "{}")
      : null,
  isLoading: false,
};

const reducer = (state: AuthState, action: any): AuthState => {
  switch (action.type) {
    case "LOGIN_INITED": {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case "LOGIN_FAILED": {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    case "LOGIN_COMPLETED":
      return {
        ...state,
        expiresAt: +action.payload.expiresAt,
        user: action.payload.user,
        isLoading: false,
      };
    case "LOG_OUT":
      return {
        ...state,
        expiresAt: 0,
        user: null,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ domain, clientId, children }) => {
  const auth0 = new WebAuth({
    domain: domain,
    clientID: clientId,
    redirectUri: `${window.location.origin}/callback`,
    responseType: "token id_token",
    scope: "openid profile email offline_access",
    audience: `http://mg-group.com.ar/apis`,
  });

  initialState["auth0"] = auth0;
  const [authState, authDispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
