import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react";
import { useAuth } from "components/app/auth/useAuth";
import React from "react";
import { AgenciaContext, AgenciaData } from "./agencia.context";
import useLogs from "libs/useLogs";

export const GET_AGENCIA = gql`
  query agencia($data: AgenciaInput!) {
    agencia(data: $data) {
      id
      externalId
      emails
      nombre
      path
      whatsapp
      direccion {
        provinciaId
        provincia
        domicilio
        localidad
        codigoPostal
        lat
        lng
      }
      imagenPerfil
      fechaAlta
    }
  }
`;

export const AgenciaProvider = ({ children }) => {
  const { canal, isLoading: loadingAuth, user } = useAuth();
  const { logger } = useLogs();

  const isAgenciero = user?.userType === "Agenciero";
  const {
    data,
    loading: loadingAgencia,
    error,
    refetch,
  } = useQuery<AgenciaData>(GET_AGENCIA, {
    skip: !(isAgenciero && canal && canal > 0),
    variables: { data: { externalId: canal } },
    onError: (error) => {
      logger.error({ error }, "Error on Query - [ GET_AGENCIA ]");
    },
  });

  const loading = loadingAgencia || loadingAuth;
  return (
    <AgenciaContext.Provider
      value={{ agencia: data?.agencia, loading, error, refetch }}
    >
      {children}
    </AgenciaContext.Provider>
  );
};
